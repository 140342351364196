export const slugify = (string: string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const extractFirstSentence = (text: string) => {
  return text.split(/([.!?])/).reduce((acc, part, index, array) => {
    if (index === 0 && array[index + 1]) {
      acc = part + array[index + 1];
    }
    return acc;
  }, '');
};

export const validateName = (value: string, error: string) => {
  if (!value) return ' ';

  if (value.length < 2 || !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/.test(value)) return error;
  return '';
};

export const validatePhoneNumber = (value: string, error: string) => {
  if (!value) return ' ';

  if (!/^\+?\d+$/.test(value)) return error;
  return '';
};

export const validateEmail = (value: string, error: string) => {
  if (!value) return ' ';

  if (!/\S+@\S+\.\S+/.test(value)) return error;
  return '';
};
